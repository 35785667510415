export enum TWinstonLevel {
  Error = "error",
  Info = "info",
  Warn = "warn",
}

type TSendGcpErrorLog = {
  level: TWinstonLevel
  message: string
  metadata?: Record<string, unknown>
}

const sendGcpLog = ({ level, message, metadata }: TSendGcpErrorLog) => {
  fetch("/api/gcp_log", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      level: level,
      message: message,
      metadata: metadata,
    }),
  }).catch((error) => console.error("Error sending log:", error))
}

export default sendGcpLog
