/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GraphQLClient } from "graphql-request"
import {
  type RequestMiddleware,
  type Response,
} from "graphql-request/build/esm/types"
import {
  checkUnauthorized,
  getObjectKeys,
  graphqlClientMiddlewareV2,
  LIST_ERROR_KEYWORD,
  TWinstonLevel,
  type TRequest,
  sendGcpLog,
} from "shared-utils"
import { getUserInfo } from "shared-utils/authentication"

import { signOut, signIn } from "@/authentication/authService"
import { GRAPHQL_URL } from "@/config/api"
import { AUTH } from "@/config/client"
import { type GenericError } from "@/federatedGql/graphql"

const middleware: RequestMiddleware = (request) => {
  const userInfo = getUserInfo()
  const isAuthenticated = Boolean(userInfo?.user?.id)

  const resultMiddleware = graphqlClientMiddlewareV2({
    request: request as TRequest,
    signOut,
    app: "BUYER",
    baseUrl: AUTH.BASE_URL,
  })

  if (isAuthenticated) {
    const isMutation = request.body?.toString().includes("mutation")

    sendGcpLog({
      level: TWinstonLevel.Info,
      message: `OPERATION: ${isMutation ? "MUTATION" : "QUERY"} | NAME: ${
        request.operationName
      }`,
      metadata: {
        userInfo: {
          username: userInfo?.user?.username,
          email: userInfo?.user?.email,
          personaId: userInfo?.user?.personaId,
        },
        variables: request.variables,
      },
    })
  }

  return resultMiddleware
}

export const responseMiddleware: (
  response: Error | Response<unknown>
) => void = (response) => {
  if (checkUnauthorized(response)) {
    signIn("refreshToken")
  }

  const userInfo = getUserInfo()
  const isAuthenticated = Boolean(userInfo?.user?.id)
  const hasErrorKeyword = LIST_ERROR_KEYWORD.some((key) =>
    getObjectKeys(response).includes(key)
  )

  if (isAuthenticated && hasErrorKeyword) {
    const data = (response as Response<{ data: unknown }>).data
    const entry = Object.entries(data).find(
      ([, obj]) => (obj as GenericError).reqId
    )
    const [operationName, errorData] = entry || [null, { message: "-" }]

    sendGcpLog({
      level: TWinstonLevel.Error,
      message: `OPERATION_NAME: ${operationName} | MESSAGE: ${
        (errorData as GenericError).message ?? "-"
      }`,
      metadata: {
        userInfo: {
          username: userInfo?.user?.username,
          email: userInfo?.user?.email,
          personaId: userInfo?.user?.personaId,
        },
        error: data,
      },
    })
  }

  return response
}

export const federatedGqlClient = new GraphQLClient(GRAPHQL_URL, {
  requestMiddleware: middleware,
  responseMiddleware: responseMiddleware,
  credentials: "include",
})
