function getObjectKeys(obj: Record<string, any>): string[] {
  const keys = new Set<string>()

  function findKeys(o: Record<string, any>) {
    for (const [key, value] of Object.entries(o)) {
      keys.add(key)
      if (typeof value === "object" && value !== null) {
        findKeys(value)
      }
    }
  }

  findKeys(obj)
  return Array.from(keys)
}

export default getObjectKeys
